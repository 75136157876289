import Swiper, { Navigation, Pagination } from 'swiper';
import { desktopGutter, lg } from '../../common/variables.js';

function initSwiper(element) {
  const slidesPerView = element.dataset.slidesPerView ?? 2;
  new Swiper(element, {
    modules: [Navigation, Pagination],
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      [lg]: {
        slidesPerView,
        spaceBetween: desktopGutter,
      },
    },
  });
}

export function article() {
  document.querySelectorAll('.article__swiper').forEach((element) => initSwiper(element));
}
