import Alpine from 'alpinejs';
import { article } from '../components/article/article.js';
import { initAlpinePlugins, initCommonJS } from './common.js';

initCommonJS();

window.Alpine = Alpine;
initAlpinePlugins(Alpine);
Alpine.start();

article();
